import { upperFirst, camelCase } from "lodash";
import Swal from "sweetalert2";
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';


/**
 * 計數器
 * @param {*} options 
 * @returns {Counter}
 */
function Counter(options) {
    var timer;
    var instance = this;
    var seconds = options.seconds || 10;
    var onUpdateStatus = options.onUpdateStatus || function () { };
    var onCounterEnd = options.onCounterEnd || function () { };
    var onCounterStart = options.onCounterStart || function () { };

    function decrementCounter() {
        onUpdateStatus(seconds);
        if (seconds === 0) {
            stopCounter();
            onCounterEnd();
            return;
        }
        seconds--;
    };

    function startCounter() {
        onCounterStart();
        clearInterval(timer);
        timer = 0;
        decrementCounter();
        timer = setInterval(decrementCounter, 1000);
    };

    function stopCounter() {
        clearInterval(timer);
    };

    return {
        start: function () {
            startCounter();
        },
        stop: function () {
            stopCounter();
        }
    }
};



/**
 * 比較一組時段鎮列是否有重疊
 * @param {*} timeSegments 
 * @returns {Boolean}
 */
function checkOverlap(timeSegments){
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
        timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
        const currentEndTime = timeSegments[i][1];
        const nextStartTime = timeSegments[i + 1][0];

        if (currentEndTime > nextStartTime) {
            return true;
        }
    }

    return false;
};

/**
 * 檢測目標time是否有跟一組segment重疊
 * @param {*} time 
 * @param {*} timArr 
 * @returns {Boolean}
 */
function checkOverlapWithtimArr(timeStart, timeEnd, timArr){
    var isOverlap = false;
    var timeStart = moment(timeStart, 'HH:mm');
    var timeEnd = moment(timeEnd, 'HH:mm');

    timArr.some(val=>{

        var timeValStart = moment(val[0], 'HH:mm');
        var timeValEnd = moment(val[1], 'HH:mm');

        if ( timeStart._d.getTime() > timeValStart._d.getTime() && timeStart._d.getTime() < timeValEnd._d.getTime() ) {
            isOverlap = true;
            return true
        }
    })
    

    return isOverlap;
};

/**
 * api回傳值parse
 * @param {*} res - ajax res 資料
 * @param {Function} success - 成功 callback
 * @param {Function} fail  - 失敗 callback
 */
function ajax200Res(res, success, fail){

    if ( res ) {
        const { data, status } = res;

        if ( data ) {

            const resData = data['res_data'];
            const msg = data["res_content"];
            const dataToSend = resData ? resData : data

            if ( status === 200 && data["res_code"] === 1 ) {

                if ( 'function' === typeof success ) {
                    success({res, data:dataToSend, msg})
                }

            } else {

                const defaultAlert = () => { 

                    Swal.fire({
                        icon: "error",
                        title: "訊息提示",
                        text: data.res_content,
                    });
                    
                }

                if ( 'function' === typeof fail ) {
                    fail({res, data:dataToSend, msg, defaultAlert})
                }

            };

        }
    }
}


/**
 * rwd
 */
function rwdVal(minScreen, minValue, maxScreen, maxValue, viewWidth = window.innerWidth){
    var a = (maxValue - minValue) / (maxScreen - minScreen);
    var b = minValue - a * minScreen;
    var result = 0;

    if (b < 0) {
        result = (a * viewWidth - Math.abs(b))
    } else {
        result = (a * viewWidth + b)
    }

    if ( !result ) {
        result = 0;
    }

    return result;
}

function appRwdVal(minScreen, minValue, maxScreen, maxValue){
    var viewWidth = window.innerWidth;

    if ( viewWidth > maxScreen ) {
        viewWidth = maxScreen;
    }

    if ( viewWidth < minScreen ) {
        viewWidth = minScreen
    }

    return rwdVal(minScreen, minValue, maxScreen, maxValue, viewWidth)
}

/**
 * 定義全域可取用的components
 * @param {Function} requireComponent - require.context 導入模組的方法回傳
 * @param {Object} app - Vue實例對象
 */
function covertComp2GlobalUse(requireComponent, app) {
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);
        const replaceFileName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
        const splitFileName = replaceFileName.split('/');

        const componentName = upperFirst(
            camelCase(splitFileName[splitFileName.length - 1])
        );

        app.component(
            componentName,
            componentConfig.default || componentConfig
        );
    });
}

/**
 * 物件資料內容轉換成FormData
 * @param {Object} obj - 要被轉換的物件
 * @returns 回傳FormData物件
 */
function objToFormData(obj) {
    var fd = new FormData();

    if (obj && obj.constructor === Object) {
        for (var key in obj) {
            if (
                obj.hasOwnProperty(key) &&
                obj[key] !== undefined &&
                obj[key] !== null
            ) {
                if (obj[key].constructor === Array) {
                    var keyName = key + "[]";
                    // 後端接收陣列
                    obj[key].forEach(function (val, idx) {
                        fd.append(keyName, val);
                    });
                } else {
                    // 其他值
                    fd.append(key, obj[key]);
                }
            }
        }
    }

    return fd;
}

/**
 * 卷軸套件
 * @Class MukiSimplebar
 */
function MukiSimplebar(){
    this.initialize.apply(this, arguments);
}

MukiSimplebar.prototype.initialize = function (setting) {
    var defaultSetting = {
        el: '.js-muki-simplebar',
        dragScroll: true,
        simplebar:{

        }
    }
    var newSetting = $.extend(true, {}, defaultSetting, setting);
    this.newSetting = newSetting;
    this.el = $(newSetting.el);

    var instance = this.simplebar = new SimpleBar(this.el[0], {
        autoHide: false,
        scrollbarMinSize: 5,
        // forceVisible: true,
        classNames:{
            contentWrapper: 'dragscroll'
        }
    });

    var simplebarEl =instance.el;
    var simplebarContent = instance.contentEl;
    var isHorizontalOverFlow = simplebarContent.clientWidth < simplebarContent.scrollWidth;
    var isAutoSetScrollBarEdge = false;
    var isEnableMukiDragScroll = true;

    function bindDragScroll(instance){
        var scrollEl = instance.getScrollElement();
        let isDown = false;
        let startX;
        let scrollLeft;

        scrollEl.addEventListener('mousedown', (e) => {
            isDown = true;
            scrollEl.classList.add('active');
            startX = e.pageX - scrollEl.offsetLeft;
            scrollLeft = scrollEl.scrollLeft;
        });
        scrollEl.addEventListener('mouseleave', () => {
            isDown = false;
            scrollEl.classList.remove('active');
        });
        scrollEl.addEventListener('mouseup', () => {
            isDown = false;
            scrollEl.classList.remove('active');
        });
        scrollEl.addEventListener('mousemove', (e) => {
            if (!isDown) return;

            if ( $(e.target).closest('.dragscroll_enable_select').length ) {
                // 開放游標選擇文字功能
                return
            }

            e.preventDefault();
            const x = e.pageX - scrollEl.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            scrollEl.scrollLeft = scrollLeft - walk;
        });
    }

    if (isEnableMukiDragScroll) {
        bindDragScroll(instance);
    }

    // simplebar scrollbar 間距
    if (isHorizontalOverFlow ) {

        $(simplebarEl).addClass('is-horizontal-overflow');

        if (isAutoSetScrollBarEdge) {
            $(simplebarEl).css('padding-bottom', '10px');
        }

        if (isEnableMukiDragScroll) {
            $(simplebarEl).css('cursor', 'pointer')
        }
    }

    $(this.scope).data('simplebar', instance);
}

MukiSimplebar.scrollbarObeserve = function(instance, speed){

    function check(){
        var scrollEl = instance.getScrollElement();
        var simplebarEl = $(scrollEl).closest('[data-simplebar]')
        var xOverflow = scrollEl.scrollWidth > scrollEl.clientWidth;
        var yOverflow = scrollEl.scrollHeight > scrollEl.clientHeight;

        if ( xOverflow ) {
            $(simplebarEl).addClass('is-x-overflow')
        } else {
            $(simplebarEl).removeClass('is-x-overflow')
        }

        if ( yOverflow ) {
            $(simplebarEl).addClass('is-y-overflow')
        } else {
            $(simplebarEl).removeClass('is-y-overflow')
        }
    }

    check();

    $(window).on('resize', function(){
        check();
    });

}

export { 
    Counter,
    ajax200Res,
    appRwdVal,
    covertComp2GlobalUse, 
    objToFormData, 
    MukiSimplebar,
    checkOverlap,
    checkOverlapWithtimArr
};
