import { computed, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';

function useChartHasToken(isEdit) {

    const { state, commit } = useStore();

    const token = computed(() => state.auth.token);

    if (token.value) {
        isEdit.value = true;
    }

    watch(isEdit, (isEdit) => {
        if (!isEdit) {
            commit('auth/setData', {
                token: '',
            });
        }
    });

    onUnmounted(() => {
        commit('auth/setData', {
            token: '',
        });
    });

    return {
        token
    }

}

export default useChartHasToken;