function useDraggable(list) {
    let moveX = 0,
        moveY = 0,
        startX = 0,
        startY = 0;
    let thisObj = null;
    let set_status = null;

    const dragstart = (e, obj, inverse) => {
        startX = e.clientX;
        startY = e.clientY;
        thisObj = obj;
        set_status = obj.set_status;
        if(set_status == 0) {
            obj.axis_y = e.target.offsetTop - 84;
            if(inverse){
                obj.axis_x = "30";
            }else{
                obj.axis_x = "1590";
            }
        }
    }

    const dragend = (e, obj) => {
        set_status = obj.set_status;
        if(set_status == 0) {
            return;
        }

        moveX = startX - e.clientX;
        moveY = startY - e.clientY;
        startX = e.clientX;
        startY = e.clientY;

        let x = parseInt(obj.axis_x) - moveX
        let y = parseInt(obj.axis_y) - moveY

        if (x > 1860) x = 1860;
        if (x < 0) x = 0;
        if (y > 936) y = 936
        if (y < 0) y = 0;

        obj.axis_x = x;
        obj.axis_y = y;
    }

    // 放在列表
    const dropOut = () =>{
        if(!thisObj && set_status == 1) return;
        thisObj.set_status = 0;
    }

    // 放在畫布
    const drop = () => {
        if(!thisObj && set_status == 0) return;
        thisObj.set_status = 1;
    }

    return {
        dragstart,
        dragend,
        drop,
        dropOut
    }
}

export default useDraggable