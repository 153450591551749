import {createRouter, createWebHashHistory} from "vue-router";
import store from "@/store/index";

import Home from "@/pages/Home.vue";
import Graphic from "@/pages/Graphic.vue";
import Chart from "@/pages/Chart.vue";
import History from "@/pages/History.vue";
import Setting from "@/pages/Setting.vue";
import LogIn from "@/pages/LogIn.vue";


const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/graphic",
        name: "graphic",
        component: Graphic
    }, {
        path: "/chart",
        name: "chart",
        component: Chart
    },
    {
        path: "/history",
        name: "history",
        component: History
    },
    {
        path: "/setting",
        name: "setting",
        component: Setting
    },
    {
        path: "/login",
        name: "login",
        component: LogIn
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0};
    }
});

router.beforeEach(async (to, from, next) => {
    const {name} = to;
    const token = store.state.auth.token;
    if (name === 'setting') {
        if(token) {
            next();
        }else{
            next('/login')
        }
    } else {
        next();
    }
});

export default router;
