import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import { ref, reactive, onMounted, onUnmounted } from "vue";
export default {
  props: {
    picSrc: String,
    pic: String
  },
  emit: ['update:pic', 'update:pic'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var uploadPic = function uploadPic() {
      $('#upload_picture_file').remove();
      $('body').append('<input type="file" id="upload_picture_file" accept="image/jpg,image/jpeg,image/png" style="display: none;" />');
      $('#upload_picture_file').unbind().on('change', function (input) {
        var files = input.target.files;

        for (var key in files) {
          if (!isNaN(key)) {
            var file = files[key];
            ajaxPic(file).then(function (res) {
              var uploadInfo = JSON.parse(res);
              emit('update:pic', uploadInfo.file.name);
              emit('update:picSrc', uploadInfo.file.url);
            });
          }
        }
      }).val('').click();
    };

    var ajaxPic = function ajaxPic(blob) {
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append('file', blob, 'file.png');
        console.log(fd);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/websiteApi/ajax_common/upload_picture', true);

        xhr.onload = function (evt) {
          var request = evt.target;

          if (request.status === 200) {
            resolve(request.response);
          } else {
            reject(new Error('伺服器異常'));
          }
        };

        xhr.send(fd);
      });
    };

    return {
      uploadPic: uploadPic
    };
  }
};