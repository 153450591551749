import { ref, reactive } from "vue";
import { useFieldArray } from "vee-validate";
export default {
  props: {},
  setup: function setup() {
    var cData = reactive({});

    var _useFieldArray = useFieldArray('field_list'),
        swap = _useFieldArray.swap,
        fields = _useFieldArray.fields;

    return {
      cData: cData,
      fields: fields,
      swap: swap
    };
  }
};