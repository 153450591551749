import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-16399872"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "header"
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_b_button = _resolveComponent("b-button");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h6", null, _toDisplayString($props.title), 1), $props.messageBtn ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    variant: "light",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.$emit('btnClick');
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.message), 1)];
    }),
    _: 1
  })) : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.message), 1))]);
}