import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import mukiConst from './js/const';
import Router from './js/routes';

//style
import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@/css/app.scss';


// datetimepicker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import $ from 'jquery';
import websocket from './js/websocket';

window.$ = $;
window.mukiConst = mukiConst;

const app = createApp(App);
app.use(store);
app.use(Router);
app.use(BootstrapVue3);
app.component('Datepicker', Datepicker);
app.mount('#app');

