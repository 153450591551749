import axios from 'axios';
import store from '@/store/index.js';
import { objToFormData } from '@/js/functions';
import Swal from 'sweetalert2';

// 排除在錯誤提示統一處理的api
const ErrorMsgDisableHandleArr = [
    'logout'
];

// Flag
let isTokenError = false;

// X-Csrftoken
axios.defaults.headers.common['X-Csrftoken'] = getCsrfTokenFromCookie();

const apiBase = axios.create({
    baseURL: '/websiteApi/',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        // Axios supports automatic object serialization to a FormData object 
        // if the request Content-Type header is set to multipart/form-data
        'Content-Type': 'application/form-data'
    },
    transformRequest: [
        function (data, header) {
            // 多國語系
            header.common['MukiLang'] = store.state.locale;
            // token
            header.common['userAuthToken'] = store.state.auth.token;

            return objToFormData(data);
        }
    ],
    transformResponse: [
        function (res) {
            apiBase.defaults.headers.common['X-Csrftoken'] = getCsrfTokenFromCookie();

            try {

                var parsed = JSON.parse(res);
                return parsed;

            } catch (err) {

                Swal.fire({
                    title: '訊息提示',
                    text: '資料發生錯誤!',
                    icon: 'error',
                    confirmButtonText: '確定',
                }).then(() => {
                });
            }
        }
    ],
});
const apiBaseBlob = axios.create({
    baseURL: '/websiteApi/',
    withCredentials: false,
    transformRequest: [
        function (data, header) {
            return objToFormData(data);
        }
    ],
});


apiBase.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

apiBase.interceptors.response.use(function (config) {
    const { data } = config;

    // 處理沐奇自定義的錯誤代碼
    if (data) {
        const { res_code } = data;

        if (ErrorMsgDisableHandleArr.indexOf(config.url) === -1) {
            // token過期
            switch (res_code) {
                // 登入 token
                case mukiConst.token.INVALID:

                    if (isTokenError) return;

                    isTokenError = true;

                    Swal.fire({
                        title: '訊息提示',
                        text: '操作時間過長，請重新登入!',
                        icon: 'info',
                        confirmButtonText: '確定',
                    }).then(() => {
                        Logout();
                    });

                    break;
                // csr token
                case mukiConst.csrToken.INVALID:

                    if (isTokenError) return;

                    isTokenError = true;

                    Swal.fire({
                        title: '訊息提示',
                        text: '操作時間過長，請重新登入!',
                        icon: 'info',
                        confirmButtonText: '確定',
                    }).then(() => {
                        Logout();
                    });


                    break;
                default:
                    break;
            }

        }

        if (isTokenError) {
            return Promise.reject('錯誤');
        }
    }

    return config;
}, errorFunction);

function errorFunction(error) {

    // Do something with request error
    if (ErrorMsgDisableHandleArr.indexOf(error.config.url) === -1) {
        if (error.response) {
            // server responded status code falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                    if (error.response.data && error.response.data.Message) {
                        Swal.fire({
                            title: '訊息提示',
                            text: error.response.data.Message,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    } else {
                        Swal.fire({
                            title: '訊息提示',
                            text: `${error.response.status}: 資料來源不存在`,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    }

                    break;
                case 404:
                    if (error.response.data && error.response.data.Message) {
                        Swal.fire({
                            title: '訊息提示',
                            text: error.response.data.Message,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    } else {
                        Swal.fire({
                            title: '訊息提示',
                            text: `${error.response.status}: 資料來源不存在`,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    }
                    break;
                case 500:
                    if (error.response.data && error.response.data.Message) {
                        Swal.fire({
                            title: '訊息提示',
                            text: error.response.data.Message,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    } else {
                        Swal.fire({
                            title: '訊息提示',
                            text: `${error.response.status}: 內部系統發生錯誤`,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    }
                    break;
                default:
                    if (error.response.data && error.response.data.Message) {
                        Swal.fire({
                            title: '訊息提示',
                            text: error.response.data.Message,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    } else {
                        Swal.fire({
                            title: '訊息提示',
                            text: `${error.response.status}: 發生錯誤`,
                            icon: 'info',
                            confirmButtonText: '確定',
                        }).then(() => {
                        });
                    }
                    break;
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.code === 'ECONNABORTED' && error.message && error.message.indexOf('timeout') !== -1) {
                // request time out will be here

                Swal.fire({
                    title: '訊息提示',
                    text: '網路連線逾時，請點「確認」鍵後繼續使用。',
                    icon: 'info',
                    confirmButtonText: '確定',
                }).then(() => {
                });

            } else {

                Swal.fire({
                    title: '訊息提示',
                    text: '網路連線不穩定，請稍候再試',
                    icon: 'info',
                    confirmButtonText: '確定',
                }).then(() => {
                });
            }
        }
    }

    return Promise.reject(error);
}

function getCsrfTokenFromCookie() {
    let regex = /.*csrf_cookie_name=([^;.]*).*$/;
    return document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1];
}

const setApiMode = (apiBase, mode) => {
    if (mode === 'dev') {
        apiBase.defaults.baseURL = '/api/';
    }
    return apiBase;
};

function baseGet(url, formInfo, mode) {
    return setApiMode(apiBase, mode).get(url, formInfo);
}

function basePost(url, formInfo, mode) {
    return setApiMode(apiBase, mode).post(url, formInfo);
}

function basePut(url, formInfo, mode) {
    return setApiMode(apiBase, mode).put(url, formInfo);
}

function basePatch(url, formInfo, mode) {
    return setApiMode(apiBase, mode).patch(url, formInfo);
}

function baseDel(url, formInfo, mode) {
    return setApiMode(apiBase, mode).delete(url, formInfo);
}

apiBaseBlob.interceptors.response.use(function (config) {
    return config;
}, errorFunction);

function basePostBlob(url, formInfo, mode) {
    return apiBaseBlob.post(url, formInfo, { responseType: 'blob' });
}


export {
    baseGet,
    basePost,
    basePut,
    basePatch,
    baseDel,
    basePostBlob
};
