import { ref, reactive, onMounted, onUnmounted } from "vue";
export default {
  props: {
    title: String,
    message: String,
    messageBtn: {
      type: Boolean,
      default: false
    }
  },
  emits: ['btnClick']
};