import ReconnectingWebSocket from 'reconnecting-websocket';

const monitor = {

    uri: 'ws://127.0.0.1:8010',

    onMessageFunction: (e) =>{
    },

    onErrorFunction: (e) =>{
        console.log('ws 斷線');
    },

    onCloseFunction: (e) =>{
        console.log('ws 關閉');
    },

    onOpenFunction: (e) =>{
        console.log('ws 連線');
    },

    init() {
        this.socket = new ReconnectingWebSocket(this.uri)//這裡面的this都指向vue
        this.socket.open = this.open;
        this.socket.onerror = this.error;
        this.socket.onclose = this.close;
        this.socket.onmessage = this.receive;
    },
    
    error(e) {
        monitor.onErrorFunction(e);
    },
    
    receive(e) {
        monitor.onMessageFunction(e);
    },
    
    close(e) {
        monitor.onCloseFunction(e);
    },

    open(e) {
        monitor.onOpenFunction(e);
    },
    
    send(data) {
        monitor.socket.send(data);
    },
}

export default {
    monitor
}