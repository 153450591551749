import { ref, reactive, onMounted, onUnmounted } from "vue";
import moment from "moment";
export default {
  props: {
    title: String
  },
  setup: function setup() {
    var _this = this;

    var timer = null;
    var time = ref('');
    time.value = moment().format('YYYY/MM/DD HH:mm:ss');

    function getnowTime() {
      timer = setInterval(function () {
        time.value = moment().format('YYYY/MM/DD HH:mm:ss');
      }, 1000);
    }

    onMounted(function () {
      getnowTime();
    });
    onUnmounted(function () {
      clearInterval(_this.timer);
    });
    return {
      time: time
    };
  }
};