import { ref, toRef } from "vue";
import { useField } from "vee-validate";
export default {
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props) {
    var name = toRef(props, "name");

    var _useField = useField(name, undefined, {
      initialValue: props.value
    }),
        inputValue = _useField.value,
        errorMessage = _useField.errorMessage,
        handleBlur = _useField.handleBlur,
        handleChange = _useField.handleChange,
        meta = _useField.meta;

    var showPassword = ref(false);
    return {
      handleChange: handleChange,
      handleBlur: handleBlur,
      errorMessage: errorMessage,
      inputValue: inputValue,
      meta: meta,
      showPassword: showPassword
    };
  }
};