import { onUnmounted } from 'vue';
import moment from 'moment';

function useNextDay() {

    let fnSetTimeout = null;

    const diffNexDay = () => {
        const nextDay = moment().format('YYYY-MM-DD 23:59:59');
        return moment(nextDay).diff(moment());
    };

    const watchNextDay = (fn) => {
        repeatFunction(() => {
            fn();
            watchNextDay(fn);
        });
    };

    const repeatFunction = (fn) => {
        fnSetTimeout = setTimeout(fn, diffNexDay());
    };

    onUnmounted(() => {
        clearTimeout(fnSetTimeout);
    });

    return {
        watchNextDay,
    };
}

export default useNextDay;