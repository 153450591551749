import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-bf9edf5a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["for"];
var _hoisted_2 = ["name", "id", "type", "value", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-input", {
      'has-error': !!$setup.errorMessage
    }])
  }, [_createElementVNode("label", {
    for: $props.name
  }, _toDisplayString($props.label), 9, _hoisted_1), _createElementVNode("input", {
    name: $props.name,
    id: $props.name,
    type: $props.type === 'password' && !$setup.showPassword ? $props.type : 'text',
    value: $setup.inputValue,
    placeholder: $props.placeholder,
    onInput: _cache[0] || (_cache[0] = function () {
      return $setup.handleChange && $setup.handleChange.apply($setup, arguments);
    }),
    onBlur: _cache[1] || (_cache[1] = function () {
      return $setup.handleBlur && $setup.handleBlur.apply($setup, arguments);
    })
  }, null, 40, _hoisted_2), _withDirectives(_createElementVNode("p", {
    class: "help-message"
  }, _toDisplayString($setup.errorMessage), 513), [[_vShow, $setup.errorMessage || $setup.meta.valid]]), $props.icon ? (_openBlock(), _createElementBlock("i", {
    key: 0,
    class: _normalizeClass("header-icon icon-".concat($props.icon))
  }, null, 2)) : _createCommentVNode("", true), $props.type === 'password' ? (_openBlock(), _createElementBlock("i", {
    key: 1,
    class: _normalizeClass("password-icon icon-".concat($setup.showPassword ? 'eye' : 'eye-slash')),
    onClick: _cache[2] || (_cache[2] = function ($event) {
      return $setup.showPassword = !$setup.showPassword;
    })
  }, null, 2)) : _createCommentVNode("", true)], 2);
}