import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Header = _resolveComponent("Header");

  var _component_router_view = _resolveComponent("router-view");

  var _component_Footer = _resolveComponent("Footer");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Header, {
    title: $setup.headerTitle
  }, null, 8, ["title"]), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [_createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))];
        }),
        _: 2
      }, 1024)];
    }),
    _: 1
  }), _createVNode(_component_Footer, {
    list: $setup.urlList
  }, null, 8, ["list"])], 64);
}