import { basePost } from '@/js/services/baseService';

export default {
    namespaced: true,
    state: () => ({
        token: '',
    }),
    mutations: {
        setData(state, data) {
            for (let key in state) {
                if (data[key] !== undefined) {
                    state[key] = data[key];
                }
            }
        },
    },
    actions: {
        verifyToken({ state, commit }) {
            return basePost('/api_member/verify_token', {
                member_token: state.token,
            }).then((res) => {
                if (res.data.res_code == 1) {
                    return res;
                }
                commit('setData', {
                    token: '',
                });
                return new Promise((resolve, reject) => reject());
            });
        },
    },

};
