import { ref, reactive, onMounted, onUnmounted } from "vue";
export default {
  name: 'LoadingMask',
  props: {
    loading_text: String
  },
  setup: function setup(props) {
    var loadingText = props.loading_text;
    console.log(77777);
    return {
      loadingText: loadingText
    };
  }
};