import {onBeforeUnmount} from "vue";

function useUploadExcel() {

    const uploadExcel = (url , callback) =>{
        $('#upload_excel_file').remove();
        $('body').append('<input type="file" id="upload_excel_file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style="display: none;" />');
        $('#upload_excel_file').unbind().on('change', function(input) {
            var files = input.target.files;
            for(var key in files){
                if(!isNaN(key)){
                    var file = files[key];
                    ajaxPic(url, file).then(()=> {
                        callback()
                    });
                }
            }
        }).val('').click();
    }

    const ajaxPic = (url, blob) => {
        return new Promise(function(resolve, reject) {
            var fd = new FormData();
            fd.append('file', blob);
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url , true);
            xhr.onload = function(evt) {
                var request = evt.target;
                if(request.status === 200) {
                    resolve(request.response);
                }else{
                    reject(new Error('伺服器異常'));
                }
            }
            xhr.send(fd);
        });
    }

    return {
        uploadExcel
    }
}

export default useUploadExcel