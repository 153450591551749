import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import { reactive, computed } from "vue";
import { useRoute } from "vue-router";
import websocket from "./js/websocket";
export default {
  name: "App",
  setup: function setup() {
    var route = useRoute();
    var urlList = [{
      name: "首頁",
      url: "/",
      icon: "home"
    }, {
      name: "參數曲線圖",
      url: "/graphic",
      icon: "graphic"
    }, {
      name: "設備流程圖",
      url: "/chart",
      icon: "chart"
    }, {
      name: "異常紀錄",
      url: "/history",
      icon: "history"
    }, {
      name: "系統管理",
      url: "/setting",
      icon: "setting"
    }];
    var headerTitle = computed(function () {
      return [].concat(urlList, [{
        name: "系統管理",
        url: "/login"
      }]).find(function (item) {
        return item.url === route.path;
      }).name;
    });
    websocket.monitor.init();
    return {
      urlList: urlList,
      headerTitle: headerTitle
    };
  }
};