import { onUnmounted } from 'vue';

function useSetInterval() {

    let fnSetInterval = null;

    const repeatFunction = (fn) => {
        clearInterval(fnSetInterval);
        fn();
        fnSetInterval = setInterval(fn, 1000);
    };

    onUnmounted(() => {
        clearInterval(fnSetInterval);
    });

    const stopFunction = () => {
        clearInterval(fnSetInterval);
    }

    return {
        repeatFunction,
        stopFunction
    };
}

export default useSetInterval;