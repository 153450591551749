import { createStore, createLogger } from 'vuex';
import machine from './modules/machine'; // 引入modules
import auth from './modules/auth'; // 引入modules

export default createStore({
    modules: {
        auth,
        machine,
    },
    plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
});
