import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'Home',
  setup: function setup() {
    var _useStore = useStore(),
        state = _useStore.state,
        dispatch = _useStore.dispatch;

    var info = computed(function () {
      return state.machine.info;
    });
    dispatch('machine/getMachineData');
    return {
      info: info
    };
  }
};