import { basePost } from '@/js/services/baseService';

export default {
    namespaced: true,
    state: () => ({
        info: {
            machine_name: '',
            machine_code: '',
            contact_name: '',
            contact_phone: '',
            machine_ip: '',
            pic1: '',
        },
        abnormal: [],
        field: [],
    }),
    mutations: {
        setData(state, data) {
            for (let key in state) {
                if (data[key] !== undefined) {
                    state[key] = data[key];
                }
            }
        },
    },
    actions: {
        getMachineData({ commit }) {
            return basePost('/api_machine/get_machine_data', {}).then((res) => {
                commit('setData', {
                    info: res.data.res_data,
                });
                return res;
            });
        },
        getMachineAbnormalData({ commit }) {
            return basePost('/api_machine/get_machine_abnormal_data', {}).then((res) => {
                commit('setData', {
                    abnormal: res.data.res_data,
                });
                return res;
            });
        },
        getMachineFieldData({ commit }) {
            return basePost('/api_machine/get_machine_field_data', {}).then((res) => {
                commit('setData', {
                    field: res.data.res_data,
                });
                return res;
            });
        },
    },

};
