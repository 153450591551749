import { ref, reactive, onMounted, onUnmounted } from "vue";
export default {
  props: {
    list: Array
  },
  setup: function setup() {
    var isOpened = ref(false);
    return {
      isOpened: isOpened
    };
  }
};