function useExport() {

    function exportFile(content, customFileName) {
        let blob = new Blob([content], { type: 'application/vnd.ms-excel' }); // 默认excel
        let filename = content.filename || customFileName;
        let URL = window.URL || window.webkitURL;
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = objectUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    return {
        exportFile,
    };
}

export default useExport;