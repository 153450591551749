import "core-js/modules/es.number.constructor.js";
import { ref, reactive, computed } from 'vue';
export default {
  emits: ['update:modelValue', 'pageChange'],
  props: {
    // 樣式
    inline: Boolean,
    bar: Boolean,
    // 總頁數
    totalPage: {
      type: Number,
      default: 0
    },
    // 當前號碼
    modelValue: {
      type: Number,
      default: 0
    },
    // pagination的頁數 (非link數字)
    // page: {
    //     default: 1
    // },
    // 一次顯示幾頁
    perPage: {
      type: Number,
      default: function () {
        if (window.innerWidth > 1024) {
          return 10;
        } else if (window.innerWidth > 768) {
          return 5;
        } else {
          return 3;
        }
      }()
    },
    // 隱藏上一頁/下一頁說明文字
    hideNavText: {
      type: Boolean,
      default: false
    },
    // 手機板30px
    mobile30px: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    var thisTotalPage = computed(function () {
      return props.totalPage;
    });
    var thisPageNum = computed(function () {
      return props.modelValue;
    });
    var thisPage = computed(function () {
      return Math.ceil(thisPageNum.value / thisPerPage.value);
    });
    var thisPerPage = computed(function () {
      return props.perPage;
    });
    var thisHideNavText = ref(props.hideNavText); // 1 ~ 4 => 1 2 3 4
    // 5 ~ 8 => 5 6 7 8

    var to = computed(function () {
      // var result = thisPage.value * thisPerPage.value;
      // if ( result > thisTotalPage.value ) result = thisTotalPage.value;
      var result = thisPageNum.value + (thisPerPage.value - 1) / 2; // now 在第一輪數字內

      if (result <= thisPerPage.value) result = thisPerPage.value;
      if (result > thisTotalPage.value) result = thisTotalPage.value;
      return Math.ceil(result);
    });
    var from = computed(function () {
      // var result =  ((thisPage.value - 1) * thisPerPage.value) + 1;
      // if ( result < 1 ) result = 1;
      var result = thisPageNum.value - (thisPerPage.value - 1) / 2; // 在最後一輪數字內

      if (result >= thisTotalPage.value - (thisPerPage.value - 1)) result = thisTotalPage.value - (thisPerPage.value - 1);
      if (result < 1) result = 1;
      return Math.ceil(result);
    }); // console.log('to rfrom', to.value, from.value)

    var pageList = computed(function () {
      var result = [];

      if (thisTotalPage.value < 1) {} else {
        for (var i = from.value; i <= to.value; i++) {
          result.push(i);
        }
      }

      return result;
    });

    var onClickPage = function onClickPage(page) {
      if (props.modelValue === page) {
        return;
      } // v-model 在此執行


      context.emit('update:modelValue', page);
      context.emit('pageChange', page);
    };

    var onUltraPrev = function onUltraPrev() {
      context.emit('ultraPrev');
    };

    var onPrev = function onPrev() {
      context.emit('prev');
    };

    var onUltraNext = function onUltraNext() {
      context.emit('ultraNext');
    };

    var onNext = function onNext() {
      context.emit('next');
    };

    if (window.innerWidth < 375) {
      thisHideNavText.value = true;
    }

    return {
      pageList: pageList,
      thisTotalPage: thisTotalPage,
      thisPageNum: thisPageNum,
      to: to,
      from: from,
      onClickPage: onClickPage,
      onUltraPrev: onUltraPrev,
      onPrev: onPrev,
      onUltraNext: onUltraNext,
      onNext: onNext,
      thisHideNavText: thisHideNavText
    };
  }
};