import { onUnmounted } from 'vue';
import moment from 'moment';

function useSetTime() {

    let fnSetTimeout = {};
    let fnSetInterval = {};

    const minuteRepeatFunction = (fn, item, timeout) => {
        const nextMinute = moment().add(1, 'm').format('YYYY-MM-DD HH:mm:00');
        const diff = moment(nextMinute).diff(moment());
        fn(item);
        clearTimeout(fnSetTimeout[item.id]);
        fnSetTimeout[item.id] = setTimeout(() => {
            repeatFunction(fn, item, timeout);
        }, diff);
    };

    const repeatFunction = (fn, item, timeout) => {
        clearInterval(fnSetInterval[item.id]);
        fn(item);
        fnSetInterval[item.id] = setInterval(() => {
            fn(item);
        }, timeout);
    };

    const stopFunction = (id) => {
        if(fnSetTimeout[id]){
            clearTimeout(fnSetTimeout[id]);
        }
        if(fnSetInterval[id]){
            clearInterval(fnSetInterval[id]);
        }
    };

    onUnmounted(() => {
        Object.keys(fnSetTimeout).forEach(id => {
            clearTimeout(fnSetTimeout[id]);
        });
        Object.keys(fnSetInterval).forEach(id => {
            clearInterval(fnSetInterval[id]);
        });
    });

    return {
        minuteRepeatFunction,
        stopFunction
    };
}

export default useSetTime;